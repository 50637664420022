<template>
  <v-card outlined>
    <v-simple-table class="no-highlight" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Source Facility</th>
            <th class="text-left">AWD</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rows" :key="item.id" :class="item.class">
            <td>{{ item.facility }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { computed } from "vue-demi";
import { useFacilities } from "../../store/facilities.pinia";

export default {
  name: "AccordionAverageWeeklyDemand",
  props: {
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    console.log("AccordionAverageWeeklyDemand", props);
    const facilitiesStore = useFacilities();

    const rows = computed(() => {
      if (!Array.isArray(props.entities.averageWeeklyDemand)) return [];

      const results = props.entities.averageWeeklyDemand.map((item) => {
        return {
          id: item.id,
          facility: facilitiesStore.getFacilityCode(item.facility_id),
          value: item.weekly_demand,
          class: {},
        };
      });

      if (!results.length) return [];
      return [
        ...results,
        {
          id: "total",
          facility: "AWD Total",
          value: props.entities.totalAvgWeeklyDemand.total,
          class: { "font-weight-bold": true },
        },
      ];
    });

    return {
      rows,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-highlight {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
